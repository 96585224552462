"use client";
import React, { useState } from "react";
import { GrSend } from "react-icons/gr";
import Link from "next/link";
import { login } from "@/redux/authSlice";
import { useDispatch } from "react-redux";
import globalAxiosURL from "@/hooks/globalAxiosURL";
import toast from "react-hot-toast";
import { useRouter } from "next/navigation";
import Head from "@/app/head";
import { BiLoaderAlt } from "react-icons/bi";
import { FiEye, FiEyeOff } from "react-icons/fi";

const Login = () => {
  const pageTitle = "HAMS Partner";
  const pageDescription =
    "HAMS provide a partnership for every who want a entrepreneurship";
  const canonicalUrl = "https://partner.hamsfly.com/";
  const dispatch = useDispatch();
  const axiosURL = globalAxiosURL();
  const router = useRouter();
  const data = {
    email: "",
    password: "",
  };
  const [showPass, setShowPass] = useState(false);
  const [inputData, setInputdata] = useState(data);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(false);

  const handleInput = (event) => {
    setInputdata({ ...inputData, [event.target.name]: event.target.value });
  };

  const submit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!inputData.email || !inputData.password) {
      toast.error("All Fields are Mandatory !!");
      setLoading(false);
    } else {
      try {
        const response = await axiosURL.post("/agentAuth/login", inputData);

        // localStorage.setItem("agentToken", response.data.token);
        // localStorage.setItem("agentEmail", response.data.agent.email);
        toast.success("Signed In");

        router.push("/");
        dispatch(
          login({
            user: response.data.agent.firstName,
            email: response.data.agent.email,
            token: response.data.token,
          })
        );
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Invalid email or password.");
          setLoading(false);
        } else {
          toast.error("Something went wrong. Please try again later.");
          setLoading(false);
        }
      }
    }
  };

  return (
    <div className="justify-center items-center lg:flex gap-48 h-screen">
      <Head
        title={pageTitle}
        description={pageDescription}
        canonicalUrl={canonicalUrl}
      />
      <div>
        <h1 className="lg:text-[50px] md:text-[50px] text-[30px] font-bold leading-tight text-[#007a3b]">
          <span className="lg:ml-0 md:ml-6 ml-5">Best travel</span>{" "}
          {/* <GrSend className="lg:-mt-14 md:-mt-14 -mt-9 lg:ml-56 md:ml-64 ml-40 absolute" />{" "} */}
          <br /> partners protal <br /> of the world{" "}
        </h1>
        <h3 className="lg:mt-10 md:mt-10 mt-5 lg:text-lg md:text-lg text-sm text-[#4CAF50] font-medium">
          <span className="lg:ml-0 md:ml-14 ml-4">
            We help to take your travel
          </span>{" "}
          <br />{" "}
          <span className="lg:ml-0 md:ml-7 -ml-2">
            business to the next level with our
          </span>{" "}
          <br />{" "}
          <span className="lg:ml-0 md:ml-14 ml-4">
            team&apos;s collaborative effort.
          </span>
        </h3>
        <Link href="/signIn/register">
          <button className="lg:mt-10 md:mt-10 mt-5 lg:ml-0 md:ml-20 ml-12 rounded-md bg-green-700 hover:bg-green-600 duration-200 lg:w-[150px] lg:h-[50px] md:w-[150px] md:h-[50px] w-[110px] h-[30px] text-white">
            Get Started
          </button>
        </Link>
      </div>
      <div className="">
        <form
          onSubmit={submit}
          className="border shadow rounded-lg lg:w-[500px] p-10"
        >
          <div className="">
            <h1 className="text-green-600 lg:text-2xl md:text-2xl text-xl font-medium">
              Sign in to Your Account
            </h1>
            <div>
              <p className="mt-8 text-sm text-gray-600 font-medium">
                Email<span className="text-red-600">*</span>
              </p>
              <input
                type="email"
                name="email"
                value={inputData.email}
                onChange={handleInput}
                placeholder="Enter email"
                className="w-full border rounded-md mt-2 p-3"
              />
              <p className="mt-6 text-sm text-gray-600 font-medium">
                Password<span className="text-red-600">*</span>
              </p>
              <div className="relative w-full flex items-center mt-2">
                <input
                  type={showPass ? "text" : "password"}
                  name="password"
                  value={inputData.password}
                  onChange={handleInput}
                  placeholder="Enter password"
                  className="w-full border rounded-md p-3"
                />
                <div
                  onClick={() => setShowPass(!showPass)}
                  className="absolute right-4 text-gray-700 cursor-pointer"
                >
                  {showPass ? <FiEyeOff size={16} /> : <FiEye size={16} />}
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-6">
              <div className="flex">
                <input type="checkbox" />
                <p className="ml-2 text-sm mt-0.5">Remember me</p>
              </div>
              <p>
                <Link
                  href="/signIn/forgetPassword"
                  className="text-green-600 text-sm hover:underline hover:underline-offset-2"
                >
                  Forgot Your Password ?
                </Link>
              </p>
            </div>
            <button className="mt-10 text-center rounded-md bg-green-700 hover:bg-green-600 w-full p-3 text-white duration-200">
              {loading ? (
                <BiLoaderAlt size={25} className="animate-spin mx-auto" />
              ) : (
                "Sign In"
              )}
            </button>
            <div className="mt-5 text-center">
              <p className="text-gray-600  inline-block">
                Don't have an account?
              </p>{" "}
              <Link
                href="/signIn/register"
                className="text-green-600 hover:underline hover:underline-offset-2"
              >
                Register Now
              </Link>
            </div>
            <br />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
