import Head from "@/app/head";
import FlightBanner from "@/components/flightsHome/banner/FlightBanner";
import FlightFeatures from "@/components/flightsHome/featuresSection/FlightFeatures";
import FlightRating from "@/components/flightsHome/FlightRating";
import SpecialOffers from "@/components/flightsHome/SpecialOffers";
import CheapFlights from "@/components/flightsHome/CheapFlights";
import MobileBanner from "@/components/flightsHome/banner/MobileBanner";
import RecentSearches from "@/components/flightsHome/RecentSearches";
import Partners from "@/components/flightsHome/Partners";

const FlightsHome = () => {
  const pageTitle =
    "HAMS Fly: This is a clever method for locating cheap flights.";
  const pageDescription =
    "HAMS FLY is totally changing the game in global exploration with its ground-breaking technology. It's making traveling around the world a smooth sail and opening up unlimited experiences for travelers.";
  const canonicalUrl = "https://www.hamsfly.com/flights";
  return (
    <div>
      <Head
        title={pageTitle}
        description={pageDescription}
        canonicalUrl={canonicalUrl}
      />
      <div className="hidden md:flex">
        <FlightBanner />
      </div>
      <div className="flex md:hidden">
        <MobileBanner />
        <hr />
      </div>
      <div className="container max-w-[1200px] mt-2 mx-auto px-4 lg:px-0  md:mt-20 ">
        <RecentSearches />
        <SpecialOffers />
        <Partners />
        {/* <FlightFeatures /> */}
        {/* <FlightRating /> */}
        {/* <CheapFlights /> */}
      </div>
    </div>
  );
};

export default FlightsHome;
